import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Helmet from 'react-helmet'

import { H3 } from '../components/H123456'
import Layout from '../components/layout'
import MainContent from '../components/mainContent'

export default props => (
  <Layout location={props.location}>
    <Helmet>
      <title>Contatti</title>
    </Helmet>
    <MainContent>
      <Container>
        <Row>
          <Col className='post-single'>
            {/* <Col md='8' className='m-auto'>
              <div className='post-head'>
                <H1 className='post-title'>Contacts</H1>
              </div>
            </Col> */}
            <Col md='8' className='post-content-wrap m-auto'>
              <H3>Contact me</H3>
              <p>
                Send message using below form, We will get back to you as soon
                as possible.
              </p>
              <form name='contact' method='POST' data-netlify>
                <input name='name' placeholder='Full name' type='text' />
                <input name='email' placeholder='Email' type='email' />
                <textarea name='message' placeholder='Message' />
                <input
                  className='btn btn-primary'
                  value='Send Message'
                  rows='4'
                  type='submit'
                />
              </form>
            </Col>
          </Col>
        </Row>
      </Container>
    </MainContent>
  </Layout>
)
